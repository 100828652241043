<template>
  <div id="kuaijieche">
    <div class="plans" :class="{ 'is-mobile': !isPC }">
      <div class="plan-wrapper-mask" :class="{
        advance: carType == 'advance'
      }">
        <div class="plan-wrapper" :class="{
          advance: carType == 'advance'
        }">
          <img class="plan-logo" style="width: 3.04rem; height: 2rem" src="../../assets/plan-logo.png" />
          <div class="plan-label" :class="{
            advance: carType == 'advance'
          }">
            ONLINE
          </div>

          <div class="plan" :class="{
            advance: carType == 'advance'
          }" @click="planSelect(idx)" :key="idx" v-for="(plan, idx) in plans">
            <div class="label" v-if="plan.label">{{ plan.label }}</div>
            <div class="unit">{{ plan.name }}</div>
            <div class="price">
              <span class="currency">￥</span>
              {{ (plan.price * plan.discount).toFixed(1) }}
            </div>
            <div class="discount" v-if="plan.discount != 1">
              <div class="raw-price">￥{{ plan.price }}</div>
              立省￥{{ (plan.price * (1 - plan.discount)).toFixed(1) }}
            </div>
            <div class="discount" style="margin-bottom: 0.32rem" v-else></div>
          </div>
        </div>
      </div>
    </div>
    <div class="shangche">
      <div class="wrap" @click="shangche" :class="{ advance: carType == 'advance' }">
        立即成为家庭会员
      </div>
    </div>
    <div class="warning">
      <svg t="1611487934591" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="4157" width="200" height="200">
        <path
          d="M512 64C264.58 64 64 264.58 64 512s200.58 448 448 448 448-200.58 448-448S759.42 64 512 64z m0 752a36 36 0 1 1 36-36 36 36 0 0 1-36 36z m51.83-551.95L548 636a36 36 0 0 1-72 0l-15.83-371.95c-0.1-1.33-0.17-2.68-0.17-4.05a52 52 0 0 1 104 0c0 1.37-0.07 2.72-0.17 4.05z"
          p-id="4158" fill="#8a8a8a"></path>
      </svg>
      <div class="desc">国服、儿童账号请勿购买</div>
    </div>
    <div class="divider"></div>
    <loading :display="showLoading"></loading>
    <Prompt :visible="mpPrompt">
      <template #header>
        <div class="prompt-header">如何上车</div>
      </template>
      <template #body>
        <div class="prompt-body">
          <div class="prompt-text1">因业务升级，推荐您到我们的小程序「真鱿趣」下单更方便哦，历史订单也可以同步到小程序</div>
          <div class="prompt-text2">1.长按保存识别下方公众号二维码</div>
          <div class="prompt-text2">2.点击公众号底部菜单「会员拼车」</div>

          <div class="img-wrapper">
            <img src="../../assets/official.png" />
          </div>


        </div>
      </template>
      <template #footer>
        <div class="prompt-footer" @click="mpPrompt = false">知道了</div>
      </template>
    </Prompt>

  </div>
</template>

<script>
// @ is an alias to /src
import { getPlans } from '@/api/carpool/index'
import { newOrder } from '@/api/carpool/order'
import Loading from '@/components/Loading'
import Plans from '@/components/Plans'
import Prompt from '@/components/Prompt'
import { reportError } from '@/utils/log'
import { utils } from '@/utils'
import { events } from '@/api/common'

export default {
  name: 'Home',
  data() {
    return {
      mpPrompt: false,
      plansDisplay: false,
      showLoading: false,
      planActIdx: 0,
      plans: [],
      carType: 'general',
      isPC: this.$isPC
    }
  },
  components: {
    loading: Loading,
    Plans,
    Prompt
  },
  watch: {
    '$route.query': function (newV) {
      if (newV.type) {
        this.carType = newV.type
        this.getPlans()
      }
    }
  },
  methods: {
    async getPlans() {
      try {
        let res = await getPlans({
          type: this.carType == 'general' ? ['快捷车'] : ['高级快捷车']
        })
        if (res.data.code == 0) {
          this.plans = res.data.plans
        }
      } catch (error) {
        reportError(error)
      }
    },
    async shangche() {
      try {
        if (this.$route.query.allowOrder) {
          let orderParams = {
            skuId: this.plans[this.planActIdx].id,
            uuid: localStorage.getItem('uuid'),
            cf: localStorage.getItem('cf')
          }
          this.showLoading = true
          let res = await newOrder(orderParams)
          this.showLoading = false
          if (res.data.code == 0) {
            this.$router.push(
              `/carpool/shangche?orderid=${res.data.order.orderId}`
            )
          }
        } else {
          this.mpPrompt = true
        }
      } catch (error) {
        reportError(error)
      }
    },
    planSelect(idx) {
      this.planActIdx = idx
    }
  },
  created() {
    if (this.$route.query.type) {
      this.carType = this.$route.query.type
    }
    this.getPlans()
  }
}
</script>

<style lang="less" scoped>
#kuaijieche {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 7.5rem;
  flex-shrink: 0;

  .plans {
    display: flex;
    width: 7.5rem;
    align-items: center;
    background: white;
    justify-content: center;

    .plan-wrapper-mask {
      background: #53cca8;
      border-radius: 0.16rem;
      width: 6.58rem;
      height: 2.36rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 0.04rem 0.02rem rgba(83, 204, 168, 0.2);

      &.advance {
        background: #a96c3d;
      }

      .plan-wrapper {
        flex-shrink: 0;
        white-space: nowrap;
        width: 6.54rem;
        height: 2.32rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        border-radius: 0.16rem;
        background: #ebfff9;
        position: relative;

        &.advance {
          background: #fff8f2;
        }

        .plan-logo {
          position: absolute;
          bottom: 0;
          right: 0.24rem;
        }

        .plan-label {
          width: 1.2rem;
          height: 0.38rem;
          background: #53cca8;
          border-radius: 0.04rem;
          display: flex;
          align-items: center;
          position: absolute;
          font-size: 0.28rem;
          color: #ffffff;
          right: 1.6rem;
          top: 0.3rem;

          &.advance {
            background: #a96c3d;
          }

          justify-content: center;
        }

        .plan {
          width: 3rem;
          height: 2.32rem;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background: #ebfff9;

          &.advance {
            background: #fff8f2;
          }

          border-top-left-radius: 0.16rem;
          border-bottom-left-radius: 0.16rem;

          .label {
            position: absolute;
            top: -0.01rem;
            left: -0.01rem;
            font-size: 0.2rem;
            color: #eae3ac;
            height: 0.36rem;
            padding-left: 0.12rem;
            padding-right: 0.12rem;
            background: #3c3a3a;
            display: flex;
            align-items: center;
            border-top-left-radius: 0.16rem;
            border-bottom-right-radius: 0.16rem;
          }

          .unit {
            margin-top: 0.32rem;
            font-size: 0.28rem;
            color: #000102;
          }

          .price {
            font-size: 0.44rem;
            color: #000102;
            font-weight: bold;
            margin-top: 0.24rem;

            .currency {
              font-weight: normal;
              font-size: 0.32rem;
              margin-right: -0.1rem;
            }
          }

          .discount {
            margin-bottom: 0.12rem;
            display: flex;
            align-items: center;
            line-height: 100%;
            font-size: 0.2rem;
            color: #8d8d8d;
            font-weight: bold;
            letter-spacing: 0.01rem;

            .raw-price {
              text-decoration: line-through;
              margin-right: 0.05rem;
            }
          }
        }
      }
    }
  }

  .shangche {
    cursor: pointer;
    width: 7.5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .wrap {
      text-align: center;
      line-height: 0.8rem;
      width: 4.36rem;
      height: 0.8rem;
      border-radius: 0.4rem;
      font-size: 0.32rem;
      color: #ffffff;
      background: linear-gradient(90deg, #53ccaa 0%, #5bd88f 100%);

      &.advance {
        background: linear-gradient(90deg, #f7dda2 0%, #fac7b3 100%);
        color: #a96c3d;
      }
    }
  }

  .prompt-header {
    height: 1rem;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #272727;
    font-size: 0.36rem;
    font-weight: 530;
  }

  .prompt-body {
    margin: 0.12rem 0;
    padding: 0.12rem 0.28rem;
    font-size: 0.28rem;
    color: #333333;
    line-height: 0.48rem;

    .img-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 2.4rem;
        max-height: 2.4rem;
        height: auto;
        width: auto;
      }
    }

  }

  .prompt-text1 {
    font-size: 0.28rem;
    color: #666666;
    margin-bottom: 0.24rem;

  }

  .prompt-text2 {

    font-size: 0.28rem;
  }

  .prompt-footer {
    border-top: 0.02rem solid #eeeeee;
    height: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.36rem;
    color: #69d9b6;
    cursor: pointer;
  }

  .warning {
    display: flex;
    align-items: center;
    width: 7.02rem;
    margin-left: 0.24rem;
    margin-right: 0.24rem;
    height: 0.24rem;
    margin-bottom: 0.24rem;

    svg {
      width: 0.24rem;
      height: 0.24rem;
      margin-top: 0.04rem;
    }

    .desc {
      margin-left: 0.06rem;
      color: #acacac;
      font-size: 0.22rem;
    }
  }

  .divider {
    width: 7.5rem;
    height: 0.12rem;
    background: #f6f7f8;
  }
}
</style>
